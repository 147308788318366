import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button2 from '../../components/Button/Button2'
import {
  CrossIcon,
  MagicWand,
  SemiCircleIcon
} from '../../components/Icons/Icons'
import { Auth } from 'aws-amplify'
import { initalizeAWSSigner } from '../../utils/AWS'
import { toast } from 'react-toastify'

const minWordsValidation = (text) => {
  const count = 50

  console.log('lenght', text.split(' ').length)
  if (text.split(' ').length < count) {
    return true
  }

  return false
}

const GenerateTrackers = ({ isOpen, onClose, onSave }) => {
  const [signerObj, setSignerObj] = useState({})
  const [generatedTrackers, setGeneratedTrackers] = useState(null)
  const [generating, setGenerating] = useState(false)
  const [brandMessage, setBrandMessage] = useState('')
  const disableButtons = generating || minWordsValidation(brandMessage)

  useEffect(() => {
    async function initalizeData() {
      const signer = await initalizeAWSSigner()
      setSignerObj(signer)
    }
    initalizeData()
  }, [])

  const generateTrackers = async () => {
    setGenerating(true)
    const req = {
      requestType: 'extractBrandMessageTopics',
      brand_message: brandMessage
    }

    const url = process.env.REACT_APP_CHAT_URL || ''
    const apiUrl = new URL(url)
    const currentSession = await Auth.currentSession()
    const token = currentSession?.getAccessToken()?.getJwtToken()
    const signedUrl = await signerObj.sign({
      method: 'POST',
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      body: JSON.stringify(req),
      protocol: apiUrl.protocol,
      headers: {
        'Content-Type': 'application/json',
        host: apiUrl.hostname,
        userAuth: 'Bearer ' + token
      }
    })
    const response = await fetch(`${url}`, { ...signedUrl })
    let completeText = ''
    try {
      if (response.status === 200 && response.body) {
        // trackEvent(mixpanelEvents.RFX_CONTENT_REFRESHED, 'SUCCESS', {}, {})
        const reader = response.body.getReader()
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          }

          const text = new TextDecoder().decode(value)
          console.log(text)
          completeText = completeText + text
        }
        completeText = completeText.replace(/__END_OF_CHAT__/g, '')
        console.log(completeText)
        if (JSON.parse(completeText)) {
          setGeneratedTrackers(JSON.parse(completeText))

          setGenerating(false)
        }

        // handleAnalysisReportChange(key, completeText)
      } else {
        // trackEvent(mixpanelEvents.RFX_CONTENT_REFRESHED, 'FAILED', {}, {})
        console.log('Something went wrong. Extraction Failed.')
      }
    } catch (error) {
      toast.error('Something went wrong. Extraction Failed.')
      setGenerating(false)
      console.log(error)
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px',
          // maxHeight: '450px',
          width: '500px',
          overflow: 'hidden',
          overflowY: 'auto'
          // height: '110px'
        }
      }}
    >
      <div className="flex justify-between items-center">
        <label className="text-sm bg-white">Message</label>
        {brandMessage === '' ? (
          <p className="m-0 text-xxs text-grey-500">min 50 words</p>
        ) : 50 - brandMessage.split(' ').length > 0 ? (
          <p className="m-0 text-xxs text-grey-500">
            need {50 - brandMessage.split(' ').length}{' '}
            {50 - brandMessage.split(' ').length > 1 ? 'words' : 'word'}{' '}
          </p>
        ) : null}
      </div>

      <textarea
        className="bg-grey-50 w-full p-3 rounded-lg font-poppins border-box mt-1"
        rows={6}
        placeholder="Type your brand message here..."
        style={{
          border: '0.75px solid var(--grey-200)',
          outline: 'none'
        }}
        onChange={(e) => {
          setBrandMessage(e.target.value)
        }}
      />

      {generatedTrackers && (
        <div className="mt-3 mb-2">
          <label className="text-sm  font-medium">Trackers</label>
          <div className="flex items-center gap-2 flex-wrap mt-1">
            {generatedTrackers.map((keyword, index) => {
              return (
                <div
                  key={index}
                  className="text-xs bg-grey-700 p-1 rounded-full px-3 text-white flex items-center cursor-pointer"
                  onClick={() => {
                    generatedTrackers(
                      generatedTrackers.filter((key) => key !== keyword)
                    )
                  }}
                >
                  {keyword} <CrossIcon className="size-3 ml-1" />
                </div>
              )
            })}
          </div>
        </div>
      )}

      <div className="flex justify-end gap-2 mt-2">
        <Button2
          secondary
          noOutline
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button2>
        {generatedTrackers ? (
          <Button2
            secondary
            disabled={disableButtons}
            onClick={() => {
              generateTrackers()
            }}
          >
            <MagicWand className="size-4" />
            Generate again
          </Button2>
        ) : (
          <Button2
            primary
            disabled={disableButtons}
            onClick={() => {
              generateTrackers()
            }}
          >
            {generating ? (
              <SemiCircleIcon className="size-4 animate-spin" />
            ) : (
              <>
                <MagicWand className="size-4" />
                Generate
              </>
            )}
          </Button2>
        )}
        {generatedTrackers && (
          <Button2
            primary
            disabled={disableButtons}
            onClick={() => {
              onSave(generatedTrackers, brandMessage)
            }}
          >
            {/* <MagicWand className="size-4" /> */}
            Add
          </Button2>
        )}
      </div>
    </Dialog>
  )
}

export default GenerateTrackers
